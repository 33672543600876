import { scan } from "react-scan"; // must be imported before React and React DOM
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import * as Sentry from "@sentry/react";

window.SENTRY_RELEASE = APP_VERSION;

Sentry.init({
    dsn: "https://f56fbd0e44844999ba2e4de08853b32d@bugmon.sum1.ru/6",
    tracesSampleRate: 0.3,
    release: APP_VERSION
});

const generateClassName = createGenerateClassName({
    disableGlobal: false,
    productionPrefix: 'icr',
    // seed: 'config'
});

scan({
    enabled: import.meta.env.VITE_ENABLE_REACT_DEBUG === 'true' && process.env.NODE_ENV === 'development',
});

ReactDOM.render(
  <React.StrictMode>
      <StylesProvider generateClassName={generateClassName}>
        <App />
      </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
