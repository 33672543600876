import React from 'react';
import Loadable from './Loadable';
import lazyWithRetry from './lazyWithRetry';
import Loading from './Loading';
import {language} from '@ksbteam/core/api/DummyTranslate';
const FrontPage =  Loadable(lazyWithRetry(() => import('../containers/FrontPage')));
const CommonPage =  Loadable(lazyWithRetry(() => import('../containers/CommonPage')));
const ModulePage =  Loadable(lazyWithRetry(() => import('../containers/ModulePage')));
const LoginPage = Loadable(lazyWithRetry(() => import('../containers/LoginPage')));
const TwoStepAuthPage = Loadable(lazyWithRetry(() => import('../containers/TwoStepAuthPage')));
const Main = Loadable(lazyWithRetry(() => import('../components/Main')));
const Roles = Loadable(lazyWithRetry(() => import('../components/Roles')));
const Users = Loadable(lazyWithRetry(() => import('../components/Users')));
const VerificationCard = Loadable(lazyWithRetry(() => import('../components/Main/Modules/VerificationData/ViewCard')));

const VerificationPage = (props) => <ModulePage><VerificationCard {...props} /></ModulePage>
const MainPage = () => <CommonPage noPadding={true}><Main/></CommonPage>;
const RolesPage = () => <CommonPage noPadding={true}><Roles/></CommonPage>;
const UsersPage = () => <CommonPage noPadding={true}><Users/></CommonPage>;


class MainMenu {
    constructor() {
        this.items = [
            {
                url: `/${language}`,
                component: FrontPage
            },
            {
                url: `/`,
                component: FrontPage
            },
            {
                url: `/${language}/login`,
                component: LoginPage,
            },
            {
                url: `/${language}/two-step-auth`,
                component: TwoStepAuthPage,
            },
            {
                title: 'menu.main',
                url: `/${language}/main`,
                component: MainPage,
                areas: [`header`]
            },
            {
                title: 'menu.roles',
                url: `/${language}/roles`,
                component: RolesPage,
                areas: [`header`],
                onlyManager: true
            },
            {
                title: 'menu.users',
                url: `/${language}/users`,
                component: UsersPage,
                areas: [`header`],
                onlyManager: true
            },
            {
                url: `/${language}/loading`,
                component: Loading
            },
            {
                url: `/${language}/verification/:obj_id`,
                component: VerificationPage
            }
        ];
        this.nonMenuItems = [
        ]
    }

    getRouterItems() {
        let routerItems = [];
        this.items.forEach(menuItem => {
            let item = Object.assign({}, menuItem), items = [];
            if (!!item.subMenu) {
                items = items.concat(item, item.subMenu.items);
            } else {
                items.push(item);
            }
            routerItems = routerItems.concat(items);
        });
        this.nonMenuItems.forEach(menuItem => {
            routerItems.push(menuItem);
        })
        return routerItems;
    }

    getHeaderItems() {
        return this.items.filter(item => !!item.areas && item.areas.indexOf('header') !== -1);
    }

    getHeaderIconsItems() {
        return this.items.filter(item => !!item.areas && item.areas.indexOf('header-icons') !== -1);
    }

    getProfileItems() {
        return this.items.filter(item => !!item.areas && item.areas.indexOf('profile') !== -1);
    }
}

export const mainMenu = new MainMenu();
