import {combineReducers} from 'redux';
import {page} from "./page";
import {common} from "./common";
import {objtype} from "./objtype";
import {usersnroles} from './usersnroles'
import {importData} from "./importData";

export default combineReducers({
    page,
    common,
    objtype,
    importData,
    usersnroles
});
