const initialState = {
    open: false,
    objTypeCode: ''
};

export function importData(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'IMPORT_DATA_OPEN_DIALOG':
            return Object.assign({}, state, {
                open: action.isOpen
            });
        case 'IMPORT_DATA_SET_TYPE_CODE':
            return Object.assign({}, state, {
                objTypeCode: action.objTypeCode
            });
        default:
            return state;
    }
}
